import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Content from "./Content";

export type GOTO = (path: string) => void;

export default function Homepage() {
  const [path, setPath] = useState(window.location.pathname.replace("/", ""));

  const goTo = (path: string) => {
    setPath(path.replace("/", ""));
    history.pushState(null, "", path.toLowerCase().replaceAll(" ", "-"));
  };

  return (
    <div className="flex flex-row w-screen justify-center py-4 shrink-0 items-start pl-2">
      <Sidebar goTo={goTo} path={path} />
      <Content goTo={goTo} path={path} />
      <div className="flex-1 shrink" />
    </div>
  );
}

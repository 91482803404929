import React from "react";
import { GOTO } from "./Homepage";
import { StickyHeader } from "./Content";

// get your glyphs here: https://www.dafont.com/consola-mono.charmap?f=0&l[]=10&back=theme

export const POSTS = [
  {
    glyph: "₪",
    title: "Requiem for a Stream: How to write Streams Good",
    subtitle: "",
    date: new Date("2020-05-01"),
  },
  {
    glyph: "⌂",
    title: "ConstructR - Changing the way we write Java",
    subtitle: "",
    date: new Date("2019-03-12"),
  },
];

const urlify = (s: string) =>
  s
    .toLowerCase()
    .replaceAll(" ", "-")
    .replace(/[^a-zA-Z0-9-_]/g, "");

export default function Posts({ goTo }: { goTo: GOTO }) {
  return (
    <div>
      <StickyHeader header="> POSTS" />
      <div className="grid grid-cols-1 divide-solid divide-y-2 gap-2 py-1">
        {POSTS.map((b) => (
          <button
            key={b.title}
            className="to-red w-full text-left pt-2"
            onClick={() => goTo("/" + urlify(b.title))}
          >
            <div className="flex gap-2 px-1 font-2xl">
              <span className="text-[#ed1c24] px-2">{b.glyph}</span>
              {b.date.getFullYear() +
                "-" +
                b.date.getMonth().toString().padStart(2, "0") +
                "-" +
                b.date.getDate().toString().padStart(2, "0")}{" "}
              - {b.title}
              <small>{b.subtitle}</small>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Projects from "./Projects";
import Posts from "./Posts";
import { GOTO } from "./Homepage";

export const StickyImage = ({ filename }: { filename: string }) => (
  <img
    alt={filename}
    src={"/pics/" + filename}
    className="sticky top-0 min-w-screen py-14 -z-100 sticky-img bg-black/[0.5]"
  />
);

export const StickyHeader = ({
  header,
  link,
}: {
  header: string;
  link?: string | undefined;
}) => {
  if (link != undefined) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <h1 className="sticky z-100 -top-1 min-w-screen font-bold text-xl py-4 z-100 bg-transparent text-[#ed1c24] sticky-header bg-black/[0.5] w-full">
          {header}
        </h1>
      </a>
    );
  } else {
    return (
      <h1 className="sticky z-100 -top-1 min-w-screen font-bold text-xl py-4 z-100 bg-transparent text-[#ed1c24] sticky-header bg-black/[0.5] w-full">
        {header}
      </h1>
    );
  }
};

const Code = ({ filename }: { filename: string }) => {
  const [cod, setCod] = useState("coding...");

  useEffect(() => {
    fetch(`/code/${filename}`).then((res) => {
      if (res.status === 200) {
        res.text().then((t) => setCod(t));
      } else {
        setCod("oops");
      }
    });
  }, [filename]);

  return (
    <div className="flex flex-col w-full text-sm py-4 relative bg-black/[0.5] items-center">
      <div className=" min-w-[90%] w-[90%] max-w-[90%] border-[#ed1c24] border-2 overflow-x-scroll bg-black/[0.75]  px-8 py-4 font-sm font-thin w-full">
        <pre>{cod}</pre>
      </div>
    </div>
  );
};

const Line = ({ s }: { s: string }) => {
  if (s.startsWith("IMG:")) {
    return <StickyImage filename={s.split(":")[1]} />;
  } else if (s.startsWith("HEAD:")) {
    return (
      <StickyHeader
        header={"> " + s.split(":").slice(1).join(":").toUpperCase()}
      />
    );
  } else if (s.startsWith("CODE:")) {
    return <Code filename={s.split(":")[1]} />;
  } else {
    return (
      <p
        className="relative bg-black/[0.5] py-2 w-full"
        dangerouslySetInnerHTML={{ __html: s }}
      />
    );
  }
};

const Paragraphs = ({ paragraphs }: { paragraphs: string[] }) => {
  return (
    <div className="flex flex-col items-center pb-[35svh]">
      {paragraphs.map((p, i) => (
        <Line key={i} s={p} />
      ))}
    </div>
  );
};

const MAGIC_LINE_BREAK = "MAGIC_LINE_BREAK_1292";

const parseParagraphs = (text: string): string[] =>
  text
    .replaceAll("\n\n", MAGIC_LINE_BREAK)
    .replaceAll("\n", " ")
    .split(MAGIC_LINE_BREAK);

const DEFAULT = "";

export default function Content({ path, goTo }: { path: string; goTo: GOTO }) {
  const customPages: Map<string, JSX.Element> = new Map([
    ["projects", <Projects key="projects" />],
    ["posts", <Posts key="projects" goTo={goTo} />],
    [DEFAULT, <Posts key="projects" goTo={goTo} />],
  ]);

  const [content, setContent] = useState(customPages.get(path));

  useEffect(() => {
    if (customPages.get(path) === undefined) {
      fetch(`/posts/${path}.rtcl`).then((res) => {
        if (res.status === 200) {
          res
            .text()
            .then((t) =>
              setContent(<Paragraphs paragraphs={parseParagraphs(t)} />)
            );
        } else {
          setContent(<Paragraphs paragraphs={["oops"]} />);
        }
      });
    } else {
      setContent(customPages.get(path));
    }
  }, [path]);

  if (content === undefined) {
    return <p>Loading</p>;
  }

  return (
    <div
      className="relative min-w-[300px] w-[750px] max-w-[750px] shrink max-h-[94svh] overflow-y-scroll px-4"
      onScroll={() => {
        const shiterator = document.getElementsByClassName("sticky-header");
        let item = shiterator.item(0);
        for (let i = 0; item !== null; i++) {
          item.classList.toggle(
            "header-pinned",
            item.getBoundingClientRect().top <= 60
          );
          item.classList.toggle(
            "header-unpinned",
            item.getBoundingClientRect().top > 60
          );
          item = shiterator.item(i);
        }
      }}
    >
      {content}
    </div>
  );
}

import React, { useState } from "react";
import { GOTO } from "./Homepage";

const buttons = ["posts", "projects", "about"];

export default function Sidebar({ goTo, path }: { goTo: GOTO; path: string }) {
  let active = path;
  if (!buttons.includes(active)) {
    active = "posts";
  }

  const [shrinkBar, setShrinkBar] = useState<boolean | undefined>();
  const isFirstRender = shrinkBar === undefined;

  return (
    <div
      className={
        "border-r flex-1 text-right shrink-0 pb-8 z-1 trans-sidebar " +
        (shrinkBar
          ? "max-w-24 w-24 min-w-24"
          : "min-w-[200px] w-[200px] max-w-auto")
      }
    >
      <button
        className={
          "flex gap-4 font-bold w-full py-4 to-red " +
          (shrinkBar
            ? "flex-col items-center pr-2 pl-2"
            : "flex-row text-2xl justify-between items-baseline pr-2 pl-6")
        }
        onClick={() => goTo("/")}
      >
        <img src="/pics/cool.png" alt="cool logo" className={"w-12"} />
        <span
          className={isFirstRender ? "" : shrinkBar ? "drop-in" : "slide-in"}
        >
          HEG.COOL
        </span>
      </button>
      {!false && (
        <div
          className={
            "flex flex-col " + (shrinkBar ? "py-1 text-sm gap-2" : "pt-8 gap-1")
          }
        >
          {buttons.map((b) => (
            <button
              key={b}
              className={"py-1 to-red text-right pr-2"}
              onClick={() => goTo("/" + b)}
            >
              <div>
                {b === active ? (
                  <span className="text-[#ed1c24]"> ► </span>
                ) : undefined}
                {b.toUpperCase()}
              </div>
            </button>
          ))}
        </div>
      )}
      <button
        className={
          "visible md:hidden mr-2 text-3xl text-[#ed1c24] " +
          (isFirstRender ? "rotate-180" : shrinkBar ? "unspin" : "spin")
        }
        onClick={() => setShrinkBar(!shrinkBar)}
      >
        ►
      </button>
    </div>
  );
}
